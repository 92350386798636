import links from '@utils/constants';

const errorData = {
  unauth: {
    title: '401',
    imagePath: '/401.svg',
    subtitle: 'You don\'t have authorization to view this page. Go back to NAPLEX Central on AccessPharmacy and try again.',
    cta: 'Go Back',
    href: links.origin,
    target: '_blank',
  },
  report: {
    title: '401',
    imagePath: '/401.svg',
    subtitle: 'You don\'t have authorization to view this page. Go back to dashboard and try again.',
    cta: 'Go to dashboard',
    href: '/dashboard',
  },
};

export {
  // eslint-disable-next-line import/prefer-default-export
  errorData,
};
